<template>
    <v-container fill-height>
        <v-layout wrap justify-center>
            <v-flex md12>
                <material-card title="Wellstar Data"
                               text="Show Wellstar invoice and quote data">

                    <w-orders-table :headers="headers" :link_type="link_type"></w-orders-table>

                </material-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<style>
    .v-data-table td, .v-data-table th {
        padding:0;
    }
</style>
<script>
    export default {
        name: "org_orders",
        data: () => ({
            //Loading data
            title: "Wellstar Data",
            link_type: 'admins',
            //Info to show
            headers: [
                {
                    text: 'Chat',
                    align: 'center',
                    value: 'chat',
                    custom: false,
                },
                {
                    text: 'Stage',
                    align: 'center',
                    value: 'stage',
                    custom: true,
                },
                {
                    text: 'Pi Number',
                    align: 'center',
                    value: 'pi_number',
                    custom: false,
                },
                {
                    text: 'Actions',
                    align: 'center',
                    value: 'actions',
                    custom: true,
                },
                {
                    text: 'Dept.',
                    align: 'center',
                    value: 'department_name'
                },
                {
                    text: 'Descr',
                    align: 'center',
                    value: 'description',
                    truncate: true
                },
                {
                    text: 'Shipped',
                    icon: 'mdi-truck-fast',
                    align: 'center',
                    value: 'shipped',
                    custom: true
                },
                {
                    text: 'Date',
                    icon: 'mdi-calendar-clock',
                    align: 'center',
                    value: 'safe_date',
                    custom: false
                },
                {
                    text: 'PO',
                    align: 'center',
                    value: 'po_number',
                },
                {
                    text: 'Fact PI',
                    align: 'center',
                    value: 'factory_pi',
                },
                {
                    text: 'Factory Inv',
                    align: 'center',
                    value: 'factory_pi',
                },
            ],
        }),
        created() {
            let user = JSON.parse(localStorage.getItem('user'));
            console.log(user.groups[0]);
            switch (user.groups[0].id) {
                case 5://customer
                    this.link_type = 'customers';
                    break;
                case 6://factory
                    this.link_type = 'factories';
                    break;
                case 13://partners
                    this.link_type = 'partners';
                    break;
            }
        }
    }
</script>


